import lazy from '../LazyComponent';

const Tasks = lazy(() => import('@/pages/TaskManagement'));

const tasklistRoutes = [
  {
    path: '/tasks',
    element: Tasks,
    meta: {
      key: 'menu.sws.tasks',
    },
  },
];

export default tasklistRoutes;
