import la from '../LazyComponent';

const KYC = la(() => import('@/pages/ReportsKYC'));
const Deposits = la(() => import('@/pages/ReportsDeposits'));
const Withdrawals = la(() => import('@/pages/ReportsWithdrawals'));

const ReportRoutes = [
  {
    path: '/reports',
    element: KYC,
    meta: {
      key: 'menu.sws.reports',
    },
  },
  {
    path: '/reports/kyc',
    element: KYC,
    meta: {
      key: 'menu.sws.reports.KYC',
    },
  },
  {
    path: '/reports/deposits',
    element: Deposits,
    meta: {
      key: 'menu.sws.reports.Deposits',
    },
  },
  {
    path: '/reports/withdrawals',
    element: Withdrawals,
    meta: {
      key: 'menu.sws.reports.Withdrawals',
    },
  },
];
export default ReportRoutes;
