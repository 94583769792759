export const enum TASK_TYPE {
  CUSTOM_TASK = 1,
  ASSIGNED_TASK = 2,
}

export const LEADS_FILTER = {
  TODAY: 'Today',
  MONTH: 'Month',
  FIRST_TRADE_ACCOUNT: 'First TradeIn Demo Account',
  INACTIVE: 'Inactive Demo Account',
};

export const RING_CENTRAL_CALLBACK_SATE = {
  HOME: 'home',
  TASK: 'task',
  DETAIL_CLIENT: 'clientdetail',
  DETAIL_LEAD: 'clientlead',
  LIST_CLIENT: 'clientlist',
  LIST_LEAD: 'leadlist',
  TEST: 'testcall',
};

export const RING_CENTRAL_CALLBACK_ROUTE = {
  [RING_CENTRAL_CALLBACK_SATE.HOME]: '/salework-station/dashboard',
  [RING_CENTRAL_CALLBACK_SATE.TASK]: '/tasks',
  [RING_CENTRAL_CALLBACK_SATE.DETAIL_CLIENT]: '/clients/information/',
  [RING_CENTRAL_CALLBACK_SATE.DETAIL_LEAD]: '/clients/information/',
  [RING_CENTRAL_CALLBACK_SATE.LIST_CLIENT]: '/clients',
  [RING_CENTRAL_CALLBACK_SATE.LIST_LEAD]: '/leads',
  [RING_CENTRAL_CALLBACK_SATE.TEST]: '/test/call',
};

export const PERMISSION_MAP = new Map([
  ['menu.sws.dashboard', '/salework-station/dashboard'],
  ['menu.sws.tasks', '/tasks'],
  ['menu.sws.messages', '/messages'],
  ['menu.sws.lead-management', '/leads'],
  ['menu.sws.client-management', '/clients'],
  ['menu.sws.sales-note', '/sales-note'],
  ['menu.sws.admin-statistics', '/adminStatistics'],
]);

export const COOKIE_TOKEN_NAME = 'swsToken';
export const COOKIE_REFRESH_TOKEN_NAME = 'swsRefreshToken';
export const PERMISSION = 'permission';
export const NO_REQUEST_AUTH_TOKEN_LIST = [
  '/api/v1/auth/session/login',
  '/api/v1/auth/session/logout',
];
export const CLIENT_TO_IB = [5, 16];

export const CLIENT_COLUMNS_SETTING = 'sws-client-list-setting';
export const LEADS_COLUMNS_SETTING = 'sws-lead-list-setting';
export const SALES_NOTE_COLUMNS_SETTING = 'sws-sales-note-list-setting';
export const REPORTS_WITHDRAWALS_COLUMNS_SETTING = 'sws-reports-withdrawals-list-setting';
export const REPORTS_DEPOSITS_COLUMNS_SETTING = 'sws-reports-deposits-list-setting';
export const REPORTS_KYC_COLUMNS_SETTING = 'sws-reports-kyc-list-setting';

// Black list constant
export const BLACK_LIST_OPTIONS = [
  {
    label: 'No Blacklist',
    value: 2,
  },
  {
    label: 'AT - Prohibit Account Transfer',
    value: 3,
  },
  {
    label: 'NTA - Note for Account Transfer',
    value: 4,
  },
  {
    label: 'RJ - Prohibit to submit withdrawals',
    value: 5,
  },
  {
    label: 'NT - Note for withdrawals',
    value: 6,
  },
  {
    label: 'RD - Prohibit to submit deposits',
    value: 7,
  },
  {
    label: 'NTD - Note for deposits',
    value: 8,
  },
  {
    label: 'RA - Prohibit to submit commissions',
    value: 9,
  },
  {
    label: 'NA - Note for commissions',
    value: 10,
  },
  {
    label: 'CA - Close Account',
    value: 11,
  },
  {
    label: 'WO - Prohibit to submit W/O',
    value: 12,
  },
  {
    label: 'CO - Note for Close Only',
    value: 13,
  },
  {
    label: 'MM-Mark and Manual Audit',
    value: 14,
  },
];
export const ALL_BLACK_LIST = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
export const MAX_SEARCH_NUMBER = 2147483647;
export const SENSITIVE_TEXT = '****';
export const SENSITIVE_NAME = 'menu.sws.security.sensitive-info';
export const DATABASE_SEARCH_SENSITIVE_NAME = 'menu.sws.general.search.sensi';
export const PROFILE_BREAD_INDEX = {
  lead: 'lead',
  client: 'client',
  kyc: 'kyc',
  deposit: 'deposit',
  withdraw: 'withdraw',
  workGroup: 'workGroup',
};
export const FILE_PREVIEW_DOWNLOAD_TYPES = [
  'pdf',
  'PDF',
  'docx',
  'DOCX',
  'doc',
  'DOC',
  'xlsx',
  'XLSX',
  'xls',
  'XLS',
  'csv',
  'CSV',
];

// client list 中包含的所有接口不能取消Floating p/l的请求
export const IGNORE_API = [
  '/api/v1/tasks/taskList',
  '/api/v1/users/followup/all/info',
  '/api/v1/profile/getClientBlackList',
  '/api/v1/profile/overView',
  '/api/v1/decrypt/email-or-phone',
  '/api/v1/enums/salesList',
  '/api/v1/message/getMessageList',
  '/api/v1/tasks/taskList',
  '/api/v1/enums/options',
];

export const TRANSFER_API = [
  '/api/v1/enums/salesList',
  '/api/v1/viewFilter/list',
  '/api/v1/user/getUserInfo',
  '/api/v1/profile/overView',
  '/api/v1/users/management/clientList',
  '/api/v1/users/management/leadsList',
  '/api/v1/reports/kyc/kycList',
  '/api/v1/deposit/queryDepositReportsList',
  '/api/v1/report/withdraw/list',
  '/api/v1/salesNote/getSalesNoteList',
  '/api/v1/salesNote/fileupload',
  '/api/v1/message/getMessageList',
  '/api/v1/tasks/taskList',
  '/api/v1/users/followup/all/info',
  '/api/v1/users/followup/additional/information/initialization',
  '/api/v1/profile/getClientDetails',
  '/api/v1/profile/getAuditInfo',
  '/api/v1/profile/followUpLogList',
  '/api/v1/web/call/list',
  '/api/v1/profile/getCampaignParticipation',
  '/api/v1/tickets/queryTicketList',
  '/api/v1/sales/group/getSalesGroupList',
  '/api/v1/sales/group/getSalesList',
  '/api/v1/statistics/template/getStatisticsTemplateList',
  '/api/v1/statistics/template/getSalesGroup',
];

export const FILTER_MODULE_INDEX = {
  lead: 'Lead Management',
  client: 'Client Management',
  salesNote: 'Sales Note',
  reportKYC: 'Reports_KYC',
  reportDeposit: 'Reports_Deposits',
  reportWithdrawal: 'Reports_Withdrawals',
};

export const TABLE_PAGINATION_SIZE_CHANGER = (total: number) => {
  // 组件默认是大于50条展示，需求改为大于10条
  return {
    showSizeChanger: (total && total > 10) || false,
  };
};
