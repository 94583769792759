import la from '../LazyComponent';

const Setting = la(() => import('@/pages/GroupSetting'));
const TemplateSetting = la(() => import('@/pages/TemplateSetting'));

const SettingRoutes = [
  {
    path: '/setting',
    element: Setting,
    meta: {
      key: 'menu.sws.admin',
    },
  },
  {
    path: '/setting/groupSetting',
    element: Setting,
    meta: {
      key: 'menu.sws.admin.workGroup',
    },
  },
  {
    path: '/setting/templateSetting',
    element: TemplateSetting,
    meta: {
      key: 'menu.sws.admin.template',
    },
  },
];

export default SettingRoutes;
