/*
 * @Author: Lucas lucas.liu@suntontech.net
 * @Date: 2023-04-03 13:56:06
 * @LastEditors: Lucas lucas.liu@suntontech.net
 * @LastEditTime: 2023-04-03 15:03:34
 * @FilePath: /newAdmin/admin-portal/src/constant/regexp.ts
 */
// RegExp 正则所需代码

export const emoji =
  // eslint-disable-next-line no-misleading-character-class
  /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030]/gi;

export const phoneReg = /^\+[1-9]\d{10,14}$/;
