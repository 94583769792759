import { Models } from '@rematch/core';
import { app } from './app';
import { ringCentral } from './ringCentral';

export interface RootModel extends Models<RootModel> {
  app: typeof app;
  ringCentral: typeof ringCentral;
}

export const models: RootModel = { app, ringCentral };
