export const currentEnv = process.env.REACT_APP_ENV;
/**
 * @description request base url
 * leopard
 * tiger
 * whale
 * beaver
 * elephant
 * buffalo
 * core-au
 * Squirrel
 */

// 测试环境地址
// https://sws-elephant.crm-alpha.com

// http://192.168.116.106:8089 // qiang ge

// http://192.168.116.83:8080   // bangwei

// http://192.168.116.178:8022  leo

// http://192.168.116.96:10162 // pengrong

export const proxyUrl = 'https://sws-core-au.crm-alpha.com';
