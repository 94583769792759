import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/common/index.css';
import VConsole from 'vconsole';
import App from './App';
import './lang/config';
import { currentEnv } from '@/constant/url';
import { matchMobile } from './utils';

if (['dev', 'test'].includes(currentEnv || '') && matchMobile()) {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const vConsole = new VConsole();
}
const render = () => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
  );
};

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.warn);
